import React, { useState, useRef, Suspense, useEffect, SyntheticEvent } from 'react'
import classes from './Item.module.css'
import Page from '../Page/Page'
import clsx from 'clsx'
import Free from '../../assets/images/free.png'
import Delivery from '../../assets/images/delivery.png'
import Quality from '../../assets/images/quality.png'
import WA from '../../assets/images/whatsapp-logo.png'
import { IItem, IItemComponent, IItemModifier, IItemValue } from '../../interfaces/Item'
import { IBadge } from '../../interfaces/Badge'
import { Await, Link, useLoaderData, useLocation } from 'react-router-dom'
import { Loader } from '../../components/loader/Loader'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { useDispatch } from 'react-redux'
import { cartActions } from '../../store/reducers/cartReducer'
import { v4 } from 'uuid'
import { getMinPrice } from '../../utils/getMinPrice'
import { ITag, ITagGroup } from '../../interfaces/TagGroup'
import NoItem from '../../assets/images/no_item.png'
import Play from '../../assets/images/play.png'
import { CustomerService } from '../../api/CustomerService'

import Heart from '../../assets/images/heart.png'
import HeartFill from '../../assets/images/heart_fill.png'
import { Helmet } from 'react-helmet-async'

type PromiseType = {
    items: Promise<IItemValue>
    dataType: string
}

const Item = () => {
    const { items } = useLoaderData() as PromiseType

    const location = useLocation()

    const descriptionRef = useRef<HTMLDivElement>(null)
    const directionRef = useRef<HTMLDivElement>(null)
    const brandRef = useRef<HTMLDivElement>(null)
    const animatedBarRef = useRef<HTMLDivElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)

    const modifierErrorRef = useRef<HTMLParagraphElement>(null)

    const [tab, setTab] = useState(descriptionRef)
    const [currentModifier, setCurrentModifier] = useState<IItemModifier>()
    const [currentImage, setCurrentImage] = useState<number>(0)
    const [favorites, setFavorites] = useState<Array<string>>([])
    const [modifiers, setModifiers] = useState<Array<IItemModifier>>([])

    const customerService = new CustomerService()

    useEffect(() => {
        customerService
            .getFavorite()
            .then((data) => {
                setFavorites(data[0].products.map((i: IItem) => i.article))
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (modifiers) setCurrentModifier(modifiers[0])
    }, [modifiers])

    useEffect(() => {
        const volume = new URLSearchParams(location.search).get('volume')

        if (volume) {
            const modifier = modifiers.find((m) => m.name === volume)
            if (modifier) {
                setCurrentModifier(modifier)
                window._tmr.push({
                    type: 'reachGoal',
                    id: 3288302,
                    value: modifier.priceSale > 0 ? modifier.priceSale : modifier.priceDefault,
                    goal: 'viewProduct',
                    params: { product_id: modifier.article },
                })
            }
        }
    }, [location, modifiers])

    useEffect(() => {
        if (currentModifier)
            window._tmr.push({
                type: 'reachGoal',
                id: 3288302,
                value: currentModifier.priceSale > 0 ? currentModifier.priceSale : currentModifier.priceDefault,
                goal: 'viewProduct',
                params: { product_id: currentModifier.article },
            })
    }, [currentModifier])

    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.load()
        }
    }, [currentImage])

    const onClickTab = (e: React.MouseEvent) => {
        switch (e.currentTarget.getAttribute('data-tab')) {
            case 'description':
                setTab(descriptionRef)
                if (animatedBarRef.current !== null) {
                    animatedBarRef.current.style.left = '0'
                    animatedBarRef.current.style.right = '752px'
                }
                break
            case 'direction':
                setTab(directionRef)
                if (animatedBarRef.current !== null) {
                    animatedBarRef.current.style.left = '105px'
                    animatedBarRef.current.style.right = '565px'
                }
                break
            case 'brand':
                setTab(brandRef)
                if (animatedBarRef.current !== null) {
                    animatedBarRef.current.style.left = '106px'
                    animatedBarRef.current.style.right = '675px'
                }
                break
        }
    }
    const dispatch = useDispatch()

    const addToCart = (product: IItem, modifier?: IItemModifier, isOneClick?: boolean) => {
        if (!modifier && product.modifiers.length > 0) {
            if (modifierErrorRef.current) modifierErrorRef.current.style.display = 'block'
            return
        }
        dispatch(
            cartActions.addToCart({
                _id: v4(),
                amount: 1,
                product,
                modifiers: modifier,
            })
        )

        // setDialogIsHidden(false)
    }

    const onError = (e: SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.src = NoItem
    }

    const getGroupName = (groups: Array<ITagGroup>, groupId: string): string => {
        const group = groups.find((g) => g._id === groupId)
        if (group) return group.name

        return ''
    }

    const getGroupTags = (groupId: string, tags: Array<ITag>): string => {
        let res: Array<string | undefined> = []

        res = tags.map((t) => {
            if (t.groups[0] === groupId) return t.name
            return undefined
        })

        return res.filter((n) => n).join(', ')
    }

    const isVideo = (url: string) => {
        if (url) return url.includes('.mp4') || url.includes('.mov') || url.includes('.x-m4v') || url.includes('.quicktime')

        return false
    }

    const addToFavorite = async (item: IItem) => {
        try {
            const isFav = isFavorite(item.article)
            const res = await customerService.editFavorite(item._id, isFav)
            setFavorites((prev) => {
                if (isFav) return prev.filter((f) => f !== item.article)
                return [...prev, item.article]
            })
        } catch (e) {
            console.log(e)
        }
    }

    const isFavorite = (code: string): boolean => {
        return favorites.includes(code)
    }

    const sortModifiers = (a: IItemModifier, b: IItemModifier) => {
        if (a.name > b.name) return 1

        if (a.name < b.name) return -1

        return 0
    }

    return (
        <Page>
            {/*<OneClickForm isHidden={dialogIsHidden} />*/}
            <div className={classes.item} itemScope itemType='http://schema.org/Product'>
                <Suspense fallback={<Loader />}>
                    <Await resolve={items} errorElement={<ErrorElement msg={''} />}>
                        {({ values }) => {
                            const [minDefaultPrice, minSalePrice] = getMinPrice(values[0])
                            setModifiers(values[0].modifiers)
                            window._tmr.push({
                                type: 'reachGoal',
                                id: 3288302,
                                value: minSalePrice > 0 ? minSalePrice : minDefaultPrice,
                                goal: 'viewProduct',
                                params: { product_id: values[0].article },
                            })
                            return (
                                <>
                                    <Helmet prioritizeSeoTags>
                                        {currentModifier ? (
                                            <title>
                                                {values[0].name} {currentModifier.name}мл
                                            </title>
                                        ) : (
                                            <title>{values[0].name}</title>
                                        )}

                                        <meta name='description' content={`Купить духи ${values[0].name} от ${minDefaultPrice} рублей в Parfumer Atelier`} />
                                        <link rel='canonical' href={`https://parfumeratelier.ru/products/${values[0].slug}`} />

                                        <meta property='og:title' content={values[0].name} />
                                        <meta property='og:description' content={`Купить духи ${values[0].name} от ${minDefaultPrice} рублей в Parfumer Atelier`} />
                                        <meta property='og:image' content={values[0].imgUrl[0]} />
                                        <meta property='og:image:alt' content={values[0].name} />
                                        <meta property='og:type' content='product' />
                                        <meta property='og:url' content={`https://parfumeratelier.ru/products/${values[0].slug}`} />
                                    </Helmet>
                                    <div className={classes.image}>
                                        <div className={classes.badges}>
                                            {values[0].badges.map((b: IBadge) => (
                                                <img key={b._id} src={b.imgUrl[0]} alt={b.name} />
                                            ))}
                                        </div>

                                        <div className={classes.otherImages}>
                                            {values[0].imgUrl.map((img: string, i: number) => {
                                                if (!isVideo(img)) {
                                                    return (
                                                        <div
                                                            key={`img_mini_${i}`}
                                                            className={clsx({
                                                                [classes.active]: currentImage === i,
                                                            })}>
                                                            <img onClick={() => setCurrentImage(i)} itemProp='image' src={img} alt={values[0].name} />
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div
                                                            key={`img_mini_${i}`}
                                                            className={clsx({
                                                                [classes.active]: currentImage === i,
                                                            })}>
                                                            <video onClick={() => setCurrentImage(i)} preload='metadata'>
                                                                <source src={`${img}#t=1.0`} type='video/mp4' />
                                                            </video>
                                                            <img src={Play} alt='Воспроизвести' onClick={() => setCurrentImage(i)} className={classes.play} />
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>

                                        <div className={classes.name}>
                                            <small itemProp='category'>{values[0].categories[0]?.name}</small>
                                            <h1 itemProp='name'>{values[0].name}</h1>
                                        </div>
                                        {!isVideo(values[0].imgUrl[currentImage]) ? (
                                            <img src={values[0].imgUrl[currentImage] || NoItem} onError={onError} alt={values[0].name} />
                                        ) : (
                                            <video ref={videoRef} preload='metadata' autoPlay controls>
                                                <source src={`${values[0].imgUrl[currentImage]}#t=1.0`} type='video/mp4' />
                                            </video>
                                        )}
                                    </div>
                                    <div className={classes.detail}>
                                        <p className={classes.code}>артикул: {values[0]?.article}</p>
                                        {values[0].modifiers && values[0].modifiers.length ? (
                                            <div className={classes.weight}>
                                                <div className={classes.modifiers}>
                                                    {[...values[0].modifiers]
                                                        .sort(sortModifiers)
                                                        // .filter((m) => m.name.trim() !== '10')
                                                        .map((m: IItemModifier) => (
                                                            <p
                                                                key={m.slug}
                                                                onClick={() => {
                                                                    if (modifierErrorRef.current) modifierErrorRef.current.style.display = 'none'
                                                                    setCurrentModifier(m)
                                                                }}
                                                                className={clsx(classes.number, {
                                                                    [classes.numberActive]: m === currentModifier,
                                                                })}>
                                                                {m.name}
                                                            </p>
                                                        ))}
                                                </div>
                                                <p>объем/мл</p>
                                            </div>
                                        ) : null}
                                        {values[0].components && values[0].components.length && values[0].article !== '72121' ? (
                                            <ul className={classes.components}>
                                                {values[0].components.map((cmp: IItemComponent) =>
                                                    cmp.name.toLowerCase().includes('атомайзер') || cmp.name === '' ? null : <li key={cmp.article}>{cmp.name}</li>
                                                )}
                                            </ul>
                                        ) : values[0].article === '72121' ? (
                                            <strong>ПРОБУЙТЕ НОВОЕ, ЭКСПЕРИМЕНТИРУЙТЕ, ВЫБИРАЙТЕ ЛУЧШЕЕ И ЭКОНОМЬТЕ С НАШИМИ АРОМАБОКСАМИ</strong>
                                        ) : null}

                                        <div className={classes.price} itemScope itemProp='offers' itemType='https://schema.org/Offer'>
                                            <meta content='RUB' itemProp='priceCurrency' />
                                            {currentModifier ? (
                                                currentModifier?.priceSale && currentModifier?.priceSale > 0 ? (
                                                    <p>
                                                        <meta content={currentModifier?.priceSale.toString()} itemProp='price' />
                                                        <span>{currentModifier?.priceSale}₽</span>
                                                        <span className={classes.priceSale}>{currentModifier?.priceDefault}₽</span>
                                                    </p>
                                                ) : (
                                                    <>
                                                        <meta content={currentModifier?.priceDefault.toString()} itemProp='price' />
                                                        <span>{currentModifier?.priceDefault}₽</span>
                                                    </>
                                                )
                                            ) : (
                                                <p>
                                                    {values[0].modifiers && values[0].modifiers.length > 0 ? (
                                                        minSalePrice > 0 ? (
                                                            <>
                                                                <meta content={minSalePrice.toString()} itemProp='price' />
                                                                <span>от {minSalePrice}₽</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <meta content={minDefaultPrice.toString()} itemProp='price' />
                                                                <span>от {minDefaultPrice}₽</span>
                                                            </>
                                                        )
                                                    ) : (
                                                        <>
                                                            <meta content={minDefaultPrice.toString()} itemProp='price' />
                                                            <span>{minDefaultPrice}₽</span>
                                                        </>
                                                    )}
                                                </p>
                                            )}
                                        </div>

                                        <div className={classes.buttons}>
                                            <button className={classes.addToCartBtn} onClick={() => addToCart(values[0], currentModifier)}>
                                                Добавить в корзину
                                            </button>
                                            <button className={classes.addToFavorite} onClick={() => addToFavorite(values[0])}>
                                                {isFavorite(values[0].article) ? (
                                                    <img src={HeartFill} title='Убрать из избранного' alt='Убрать из избранного' />
                                                ) : (
                                                    <img src={Heart} title='Добавить в избранное' alt='Добавить в избранное' />
                                                )}
                                            </button>
                                        </div>
                                        <p className='error' ref={modifierErrorRef} style={{ display: 'none', marginTop: '1rem' }}>
                                            <strong>Выберите объем</strong>
                                        </p>
                                    </div>

                                    <div className={classes.promo}>
                                        {promo.map((p, i) => (
                                            <figure key={`promo_${i}`} className={classes.promoFigure}>
                                                <img src={p.image} alt={p.name} />
                                                <figcaption>{p.name}</figcaption>
                                            </figure>
                                        ))}
                                    </div>

                                    <div className={classes.support}>
                                        <a href='https://wa.me/message/M57AZ5K4Y4NRE1' target='_blank' rel='noreferrer nofollow noopener' className={classes.content}>
                                            <span className={classes.small}>нужна помощь?</span>
                                            <span>служба поддержки</span>
                                        </a>
                                        <a href='https://wa.me/message/M57AZ5K4Y4NRE1' target='_blank' rel='noreferrer nofollow noopener' className={classes.supportImage}>
                                            <img src={WA} alt='Написать в службу поддержки' />
                                        </a>
                                    </div>
                                    <div className={classes.description}>
                                        <div className={classes.descriptionContent}>
                                            <ul role='tabpanel' className={classes.tabBar}>
                                                <li
                                                    onClick={onClickTab}
                                                    data-tab='description'
                                                    className={clsx({
                                                        [classes.active]: tab === descriptionRef,
                                                    })}>
                                                    Описание
                                                </li>
                                                {values[0].brands[0] ? (
                                                    <li
                                                        onClick={onClickTab}
                                                        data-tab='brand'
                                                        className={clsx({
                                                            [classes.active]: tab === brandRef,
                                                        })}>
                                                        бренд
                                                    </li>
                                                ) : null}
                                                <div
                                                    ref={animatedBarRef}
                                                    className={clsx(classes.animatedBar, {
                                                        [classes.prev]: tab === descriptionRef,
                                                        [classes.next]: tab !== descriptionRef,
                                                    })}
                                                    style={{
                                                        left: 0,
                                                        right: '692px',
                                                    }}
                                                />
                                            </ul>
                                            <div
                                                ref={descriptionRef}
                                                className={clsx(classes.tab, {
                                                    [classes.activeTab]: tab === descriptionRef,
                                                })}
                                                role='tab'>
                                                <div>
                                                    <h2>{values[0].name}</h2>
                                                    <p itemProp='sku'>артикул: {values[0].article}</p>
                                                    <div
                                                        itemProp='description'
                                                        dangerouslySetInnerHTML={{
                                                            __html: values[0].description,
                                                        }}
                                                    />

                                                    {values[0].groups.length > 0 ? (
                                                        <div className={classes.attrs}>
                                                            <strong>подробные характеристики</strong>
                                                            <dl className={classes.tags}>
                                                                {values[0].groups.map((group: ITagGroup) => (
                                                                    <div key={group._id} className={classes.tag}>
                                                                        <dt className={classes.tagKey}>
                                                                            <span>{getGroupName(values[0].groups, group._id)}</span>
                                                                        </dt>
                                                                        <dd className={classes.tagValue}>
                                                                            <span>{getGroupTags(group._id, values[0].tags)}</span>
                                                                        </dd>
                                                                    </div>
                                                                ))}
                                                            </dl>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {values[0].brands[0] ? (
                                                <div
                                                    ref={brandRef}
                                                    className={clsx(classes.tab, {
                                                        [classes.activeTab]: tab === brandRef,
                                                    })}
                                                    role='tab'>
                                                    <>
                                                        <h2 itemProp='brand'>
                                                            <Link to={`/brands/${values[0].brands[0].slug}`}>{values[0].brands[0].name}</Link>
                                                        </h2>
                                                        <div dangerouslySetInnerHTML={{ __html: values[0].brands[0].description }} />
                                                    </>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                            )
                        }}
                    </Await>
                </Suspense>
            </div>
        </Page>
    )
}

const promo = [
    {
        image: Free,
        name: 'Бесплатная доставка от 3000₽',
    },
    {
        image: Delivery,
        name: 'Доставка по всей России',
    },
    {
        image: Quality,
        name: 'Гарантия качества продукции',
    },
]

export default Item
