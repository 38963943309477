import React, { useMemo, useRef, useState, FC, useEffect } from 'react'
import classes from '../../pages/Cart/Cart.module.css'
import { priceWithSpace } from '../../utils/priceWithSpace'
import clsx from 'clsx'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getTotalDefaultPrice, getSalesPrice } from '../../utils/getTotalPrice'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import { cartActions } from '../../store/reducers/cartReducer'
import { PromocodeService } from '../../api/PromocodeService'
import { OrderService } from '../../api/OrderService'
import { IOrder, IProcessSale } from '../../interfaces/Order'
import { SetPromocodeType } from '../../pages/Cart/Cart'
import { useDispatch } from 'react-redux'
import { CustomerService } from '../../api/CustomerService'

export const CartCard: FC<SetPromocodeType> = ({ setPromocodeResp }) => {
    const loaderRef = useRef<HTMLDivElement>(null)

    const { cart, withdrawBonuses } = useTypedSelector((state) => state.cartReducer)
    const { user } = useTypedSelector((state) => state.userReducer)

    const dispatch = useDispatch()

    const promoTextRef = useRef<HTMLParagraphElement>(null)
    const bonusErrRef = useRef<HTMLParagraphElement>(null)
    const promoInputRef = useRef<HTMLInputElement>(null)

    const [promocode, setPromocode] = useState('')
    const [bonuses, setBonuses] = useState(0)
    const [typedBonuses, setTypedBonuses] = useState('')
    const [isPromoActive, setIsPromoActive] = useState(false)
    const [isBonusActive, setIsBonusActive] = useState(false)
    const [promocodeDiscount, setPromocodeDiscount] = useState(-1)
    const [kilbilTotalDiscounted, setKilbilTotalDiscounted] = useState<number | null>(null)
    const [kilbilTotal, setKilbilTotal] = useState<number | null>(null)
    const [loading, setLoading] = useState(false)
    const [userBalance, setUserBalance] = useState(0)

    const [availableBonuses, setAvailableBonuses] = useState(0)

    const promocodeService = new PromocodeService()
    const orderService = new OrderService()
    const navigate = useNavigate()

    useEffect(() => {
        let isMount = true
        const getBalance = async () => {
            try {
                const api = new CustomerService()
                const balance = api.getBalance()
                const ab = orderService.availableBonuses()
                const [_balance, _ab] = await Promise.all([balance, ab])
                if (isMount) {
                    setUserBalance(_balance)
                    setAvailableBonuses(_ab.available_bonuses)
                }
            } catch (e) {
                //console.log(e)
            }
        }
        getBalance().then()

        return () => {
            isMount = false
        }
    }, [])

    const onSpentBonuses = (e: React.MouseEvent) => {
        e.preventDefault()
        let withdraw = parseFloat(typedBonuses) || 0

        // if (typedBonuses === '') return

        if (withdraw > userBalance) {
            withdraw = userBalance
            setTypedBonuses(`${userBalance}`)
        }

        if (bonusErrRef && bonusErrRef.current) {
            bonusErrRef.current.classList.remove('active')
        }

        const actualPrice = getTotalDefaultPrice(cart) - getSalesPrice(cart)

        if (availableBonuses < withdraw) {
            if (bonusErrRef && bonusErrRef.current) {
                bonusErrRef.current.classList.add('active')
                return
            }
        }

        dispatch(cartActions.withdrawBOnuses(withdraw))
        setIsBonusActive(false)
        setBonuses(withdraw)
        calcCart(withdraw.toString())
    }

    useEffect(() => {
        // console.log(typedBonuses, userBalance, withdrawBonuses)
        setTypedBonuses(withdrawBonuses.toString())
    }, [withdrawBonuses])

    useEffect(() => {
        calcCart(withdrawBonuses.toString())
    }, [cart])

    useEffect(() => {
        if (loaderRef && loaderRef.current) {
            loaderRef.current.style.display = loading ? 'inline-block' : 'none'
        }
    }, [loading])

    const calcCart = (_bonuses: string) => {
        setLoading(true)
        const order: IOrder = {
            sourceId: '639c1b3a1ebccb09c5beb660',
            promocode,
            check: true,
            bonusOut: _bonuses,
        }

        setTimeout(() => {
            orderService
                .processSale(order)
                .then((data) => {
                    setKilbilTotal(data._bill_data.total)
                    setKilbilTotalDiscounted(data._bill_data.discounted_total)

                    setPromocodeResp(data)
                })
                .then(() => {
                    orderService.availableBonuses(promocode).then((data) => {
                        setLoading(false)
                        setAvailableBonuses(data.available_bonuses)
                    })
                })
                .catch(() => {
                    setLoading(false)
                })
        }, 1000)
    }

    const defaultPrice = useMemo(() => {
        return getTotalDefaultPrice(cart)
    }, [cart])

    const salesPrice = useMemo(() => {
        return getSalesPrice(cart)
    }, [cart])

    const goOrder = () => {
        if (cart.length === 0) {
            return alert('Корзина пуста')
        }
        if (user) navigate('/order')
        else navigate('/login?from=order')
        // navigate('/order')
    }

    const onPromoClick = () => {
        if (promoTextRef && promoTextRef.current) {
            promoTextRef.current.innerText = ''
            promoTextRef.current.classList.remove('add')
            promoTextRef.current.classList.remove('error')
            promoTextRef.current.classList.remove('hidden')

            promocodeService
                .checkPromocode(promocode.toUpperCase())
                .then((data) => {
                    if (promoTextRef && promoTextRef.current) {
                        if (data.result_code === '1') {
                            promoTextRef.current.classList.add('error')
                            promoTextRef.current.classList.remove('success')
                            // setPromocodeResp(undefined)
                            // setPromocodeDiscount(-1)
                        }

                        if (data.result_code === '0') {
                            // dispatch(cartActions.setPromocode([promocode, promocodeDiscount]))
                            promoTextRef.current.classList.add('success')
                            promoTextRef.current.classList.remove('error')
                            // Process sale
                            const order: IOrder = {
                                sourceId: '639c1b3a1ebccb09c5beb660',
                                promocode,
                                check: true,
                                bonusOut: typedBonuses,
                            }
                            orderService
                                .processSale(order)
                                .then((data) => {
                                    dispatch(cartActions.setPromocode([promocode, data._bill_data.discount]))
                                    setPromocodeDiscount(data._bill_data.discount)
                                    // console.log('call', data)
                                    setPromocodeResp(data)
                                    setKilbilTotalDiscounted(data._bill_data.discounted_total)
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            orderService
                                .availableBonuses(promocode)
                                .then((data) => setAvailableBonuses(data.available_bonuses))
                                .catch((err) => console.log(err))
                            promoInputRef.current!.readOnly = true
                        }

                        promoTextRef.current.innerText = data.result_text
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    const clearPromo = () => {
        promoInputRef.current!.readOnly = false
        setPromocode('')
        setLoading(true)
        const order: IOrder = {
            sourceId: '639c1b3a1ebccb09c5beb660',
            promocode: '',
            check: true,
            bonusOut: withdrawBonuses.toString(),
        }

        setTimeout(() => {
            orderService
                .processSale(order)
                .then((data) => {
                    setKilbilTotal(data._bill_data.total)
                    setKilbilTotalDiscounted(data._bill_data.discounted_total)

                    setPromocodeResp(data)
                })
                .then(() => {
                    orderService.availableBonuses().then((data) => {
                        setLoading(false)
                        setAvailableBonuses(data.available_bonuses)
                        promoTextRef.current!.innerText = ''
                    })
                })
                .catch(() => {
                    setLoading(false)
                })
        }, 1000)
    }
    useEffect(() => {
        if (availableBonuses < withdrawBonuses) {
            dispatch(cartActions.withdrawBOnuses(Math.floor(availableBonuses)))
        }
    }, [availableBonuses])

    const setBonusesHandler = (bonus: string) => {
        // console.log(bonus)

        const numBonus = parseFloat(bonus) || 0
        if (numBonus > Math.floor(availableBonuses)) return
        setTypedBonuses(numBonus.toString())
    }
    return (
        <div className={classes.detailSide}>
            {kilbilTotalDiscounted === null ? (
                <div className={clsx(classes.auth)}>
                    <Link to='/login?from=cart'>Авторизуйтесь,</Link> чтобы снизить цену
                    <div className={classes.percent}>%</div>
                </div>
            ) : null}
            <div className={clsx(classes.detail, { [classes.mute]: loading })}>
                <div className='lds-ring' ref={loaderRef}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <h2>Детали заказа</h2>
                <div className={classes.attrs}>
                    <dl className={classes.tags}>
                        <div className={classes.tag}>
                            <dt className={classes.tagKey}>
                                <span>{cart.reduce((total, c) => (total += c.amount), 0)} товара</span>
                            </dt>
                            <dd className={classes.tagValue}>
                                <span>{cart.length === 0 ? 0 : kilbilTotal ? priceWithSpace(kilbilTotal) : priceWithSpace(defaultPrice)}</span>
                            </dd>
                        </div>

                        <div className={classes.tag}>
                            <dt className={classes.tagKey}>
                                <span>Скидка</span>
                            </dt>
                            <dd className={classes.tagValue}>
                                <span>{cart.length === 0 ? 0 : kilbilTotalDiscounted && kilbilTotal ? Math.floor(kilbilTotal - kilbilTotalDiscounted - withdrawBonuses) : salesPrice}</span>
                            </dd>
                        </div>

                        {/* {promocodeDiscount >= 0 ? (
                            <div className={classes.tag}>
                                <dt className={classes.tagKey}>
                                    <span>
                                        Промокод <strong>{promocode}</strong>
                                    </span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>{-promocodeDiscount}</span>
                                </dd>
                            </div>
                        ) : null} */}
                        {withdrawBonuses > 0 ? (
                            <div className={clsx(classes.tag, classes.discount)}>
                                <dt className={classes.tagKey}>
                                    <span>бонусы</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>{priceWithSpace(-withdrawBonuses)}</span>
                                </dd>
                            </div>
                        ) : null}
                        <div className={clsx(classes.tag, classes.tagTotal)}>
                            <dt className={classes.tagKey}>
                                <span>Итого</span>
                            </dt>
                            <dd className={classes.tagValue}>
                                <span>{cart.length === 0 ? 0 : kilbilTotalDiscounted ? kilbilTotalDiscounted : priceWithSpace(defaultPrice - salesPrice)}</span>
                            </dd>
                        </div>
                    </dl>
                </div>

                {kilbilTotalDiscounted !== null ? (
                    <>
                        <button className={clsx('btn--small', { [classes.hidden]: isBonusActive })} onClick={() => setIsBonusActive(true)}>
                            Списать бонусы
                        </button>
                        <div className={clsx(classes.promoBlock, { [classes.hidden]: !isBonusActive })}>
                            <p>
                                Доступно бонусов: <strong>{Math.floor(userBalance)}</strong>
                            </p>
                            <div>
                                <input type='text' className='input' placeholder='Бонусы к списанию' value={typedBonuses} onChange={({ target: { value } }) => setBonusesHandler(value)} />
                                <button className='btn--small' onClick={onSpentBonuses}>
                                    Списать
                                </button>
                            </div>
                        </div>
                        <p ref={bonusErrRef} className='error'>
                            Максимальная сумма оплаты бонусами для этого заказа <strong>{Math.floor(availableBonuses)}₽</strong>
                        </p>

                        <div style={{ height: '.5rem' }} />

                        <button className={clsx('btn--small', { [classes.hidden]: isPromoActive })} onClick={() => setIsPromoActive(true)}>
                            Есть промокод?
                        </button>
                        <div className={clsx(classes.promoBlock, { [classes.hidden]: !isPromoActive })}>
                            <input ref={promoInputRef} type='text' className='input' placeholder='Промокод' value={promocode} onChange={({ target: { value } }) => setPromocode(value.toUpperCase())} />
                            <button className='btn--small' onClick={onPromoClick}>
                                Применить
                            </button>
                            <button className='btn--small' onClick={clearPromo} style={{ marginTop: '1rem' }}>
                                Отменить прмокод
                            </button>
                        </div>
                        <p ref={promoTextRef} className={clsx(classes.promoText, 'hidden', 'error')}>
                            Проверяем промокод...
                        </p>
                    </>
                ) : null}
                <button className='btn' style={{ marginTop: '1rem' }} onClick={goOrder}>
                    Перейти к оформлению
                </button>
            </div>
        </div>
    )
}
