import React, { Suspense, useState } from 'react'
import Page from '../Page/Page'
import classes from './Contacts.module.css'
import { IRetailPoint } from '../../interfaces/RetailPoint'
import { Await, useLoaderData } from 'react-router-dom'
import { Loader } from '../../components/loader/Loader'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import RetailPoint from './RetailPoint'
import NoRetailStore from '../../assets/images/no_retail_store.png'
// import {DateTime} from "ts-luxon"
import { Helmet } from 'react-helmet-async'
import Whatsapp from '../../assets/images/whatsapp.svg'
import Arrow from '../../assets/images/arrow_cat.svg'
import Shop from '../../assets/images/shop.jpg'
import ShopIsk from '../../assets/images/shopIsk.jpg'
type PromiseType = {
    points: Array<Promise<IRetailPoint>>
}
// _id: string
//     name: string
//     slug: string
//     imgUrl: string[]
//     externalId: string
//     externalCode: string
//     addressFull: {
//         city: string
//         street: string
//         house: string
//     }
//     address: string
//     image: string
const hardcodePoints: IRetailPoint[] = [
    {
        _id: 'Barnaul',
        name: 'ТРЦ Galaxy',
        slug: 'trc-galaxy',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Барнаул',
            street: 'пр-т Строителей',
            house: '117',
        },
        address: '1 этаж напротив входа в Ашан',
        image: Shop,
        imgUrl: [],
    },
    {
        _id: 'Iskitim',
        name: '',
        slug: 'iskitim-pushkina',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Искитим',
            street: 'ул. Пушкина',
            house: '81а/3',
        },
        address: '1 этаж, вход в магазин "Пятерочка"',
        image: ShopIsk,
        imgUrl: [],
    },
    {
        _id: 'Samara',
        name: 'ТРК Космопорт',
        slug: 'kosmoport',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Самара',
            street: 'ул. Дыбенко',
            house: '30',
        },
        address: '1 этаж центральный вход, напротив Рив Го',
        image: '',
        imgUrl: [],
    },
]
const Contacts = () => {
    const { points } = useLoaderData() as PromiseType
    const [activeCity, setActiveCity] = useState('Все города')
    const getCitiesList = (points: IRetailPoint[]) => {
        return points.reduce((acc: string[], p) => (acc.includes(p.addressFull.city) ? acc : [...acc, p.addressFull.city]), ['Все города'])
    }

    return (
        <Page>
            <Helmet prioritizeSeoTags>
                <title>Адреса наших магазинов</title>
                <link rel='canonical' href='https://parfumeratelier.ru/contacts' />
                <meta name='description' content='Адреса и телефоны магазинов аромабоксов и парфюмерии Parfumer Atelier в разных городах России.' />
                <meta property='og:title' content='Адреса наших магазинов' />
                <meta property='og:url' content='https://parfumeratelier.ru/contacts' />
                <meta property='og:description' content='Адреса и телефоны магазинов аромабоксов и парфюмерии Parfumer Atelier в разных городах России.' />
            </Helmet>
            <div className={classes.contacts}>
                <p className={classes.Title}>Федеральная сеть PARFUMER ATELIER - это проект авторской парфюмерии, созданный совместно с ведущими производителями парфюмерии со всего мира</p>
                <div className={classes.header} style={{ marginBottom: '3rem' }}>
                    <div className={classes.empty}>
                        <h2>Города</h2>
                        <Suspense fallback={<Loader />}>
                            <Await resolve={points} errorElement={<ErrorElement msg='Не удалось загрузить точки продаж' />}>
                                {(points: IRetailPoint[]) => {
                                    const cities = getCitiesList([...points, ...hardcodePoints])
                                    return (
                                        <div className={'select-box ' + classes.selectBox}>
                                            <div className='select-box__current' tabIndex={1}>
                                                {cities.map((c) => (
                                                    <div className='select-box__value' key={c}>
                                                        <input
                                                            className='select-box__input'
                                                            type='radio'
                                                            id={c}
                                                            value={c}
                                                            onChange={() => setActiveCity(c)}
                                                            name='deliveryType'
                                                            checked={c === activeCity}
                                                        />
                                                        <p className='select-box__input-text'>{c}</p>
                                                    </div>
                                                ))}
                                                <img className='select-box__icon' src={Arrow} alt='Arrow Icon' aria-hidden='true' />
                                            </div>
                                            <ul className='select-box__list'>
                                                {cities.map((dt) => (
                                                    <li key={`li_${dt}`}>
                                                        <label className='select-box__option' htmlFor={dt} aria-hidden='true'>
                                                            {dt}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                }}
                            </Await>
                        </Suspense>
                    </div>

                    <div className={classes.block}>
                        <h2>Контакты</h2>
                        <p>для связи с нами Вы можете воспользоваться следующими способами</p>

                        <div className={classes.phones}>
                            <a href='tel:+74952601003' className={classes.phone}>
                                +7 (495) 260-10-03
                            </a>

                            <a href='https://wa.me/message/M57AZ5K4Y4NRE1' className={classes.wa} target='_blank' rel='noopener noreferrer nofollow'>
                                Написать в WhatsApp
                            </a>
                        </div>
                    </div>
                    <div />
                </div>

                <Suspense fallback={<Loader />}>
                    <Await resolve={points} errorElement={<ErrorElement msg='Не удалось загрузить точки продаж' />}>
                        {(points: IRetailPoint[]) => {
                            const _points = [...points, ...hardcodePoints]
                            return (
                                <>
                                    {_points.map((p, i) => {
                                        if (activeCity === 'Все города' || activeCity === p.addressFull.city)
                                            return (
                                                <RetailPoint
                                                    key={p._id}
                                                    address={{
                                                        city: p.addressFull.city,
                                                        street: p.addressFull.street,
                                                        house: p.addressFull.house,
                                                        notice: p.address,
                                                    }}
                                                    _id={p._id}
                                                    name={p.name}
                                                    image={p.image === '' || !p.image ? NoRetailStore : p.image}
                                                    direction={i % 2}
                                                />
                                            )
                                        else return null
                                    })}
                                </>
                            )
                        }}
                    </Await>
                </Suspense>
            </div>
        </Page>
    )
}

export default Contacts
