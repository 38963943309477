import classes from './footer.module.css'
import { Link, useLocation } from 'react-router-dom'

import MasterCard from '../../assets/images/master_card.png'
import Maestro from '../../assets/images/maestro.png'
import Visa from '../../assets/images/visa.png'
import Mir from '../../assets/images/mir.png'

import VK from '../../assets/images/vk.png'
import Instagram from '../../assets/images/instagram.png'
import Logo from '../../assets/images/logo.png'
import Whatsapp from '../../assets/images/whatsapp.svg'
import React, { useEffect, useRef, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Widget } from '../widget/Widget'

const Footer = () => {
    const paths = ['/cart', '/order']
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const location = useLocation()
    const [isMinicart, setMiniCart] = useState(false)
    useEffect(() => {
        if (!paths.includes(location.pathname) && cart.length !== 0) {
            setMiniCart(true)
        } else {
            setMiniCart(false)
        }
    }, [location.pathname, cart])

    return (
        <>
            <Widget isMinicart={isMinicart} />
            <div className={classes.preFooter}>
                <div className={classes.inner}>
                    <ul role='navigation' className={classes.left}>
                        {links.map((l, i) => (
                            <li key={`footer_link_${i}`}>
                                <Link to={l.path}>{l.name}</Link>
                            </li>
                        ))}
                    </ul>
                    <div className={classes.center}>
                        <Link to='/'>
                            <div className={classes.logoBg} style={{ backgroundImage: `url('${Logo}')` }} />
                        </Link>
                    </div>
                    <div className={classes.right}>
                        <h5>служба поддержки клиентов:</h5>
                        <a href='tel:+74952601003'>+7 (495) 260-10-03</a>
                        <a href='https://wa.me/message/M57AZ5K4Y4NRE1' className={classes.wa} target='_blank' rel='noopener noreferrer nofollow'>
                            <img src={Whatsapp} alt='WhatsApp' />
                            WhatsApp
                        </a>
                        <br />
                        <Link to='/documents?block=delivery'>доставка</Link>
                        <Link to='/documents?block=reqs'>реквизиты</Link>
                        <Link to='/documents?block=offer'>договор оферты</Link>
                        <Link to='/documents?block=policy'>конфиденциальность и защита информации</Link>
                        <Link to='/documents?block=return'>правила возврата товара</Link>
                        <Link to='/documents?block=loyalty'>о программе лояльности</Link>
                        {/*<a href="https://drive.google.com/file/d/1REtbYyO1OozFqjkrMEUg2hEuiv7uvdfo/view" target="_blank" rel="noopener noreferrer nofollow">сертификаты</a>*/}
                    </div>
                </div>
            </div>
            <footer className={`${classes.footer} ${isMinicart ? classes.footerMinicart : ''}`}>
                <div className={classes.inner}>
                    <div className={classes.social}>
                        <a href='https://instagram.com/parfumer_atelier' target='_blank' rel='noopener nofollow norefferer'>
                            <img src={Instagram} alt='Parfumer Atelier Instagram' />
                        </a>
                        <a href='https://vk.com/parfumer_atelier' target='_blank' rel='noopener nofollow norefferer'>
                            <img src={VK} alt='Parfumer Atelier ВКонтакте' />
                        </a>
                    </div>
                    <p></p>
                    <div className={classes.paymentTypes}>
                        <img src={Visa} alt='Parfumer Atelier принимает к оплате карты Visa' />
                        <img src={MasterCard} alt='Parfumer Atelier принимает к оплате карты MasterCard' />
                        <img src={Maestro} alt='Parfumer Atelier принимает к оплате карты Maestro' />
                        <img src={Mir} alt='Parfumer Atelier принимает к оплате карты Мир' />
                    </div>
                    <div className={classes.copyright}>
                        <span>Parfumer Atelier 2023</span>
                    </div>
                </div>
            </footer>
        </>
    )
}

const links = [
    {
        path: '/categories/parfyumeriya',
        name: 'Парфюмерия',
    },
    {
        path: '/categories/aromaboksy',
        name: 'Аромабоксы',
    },
    {
        path: '/brands',
        name: 'Бренды',
    },
    {
        path: '/promo',
        name: 'Акции',
    },
    {
        path: '/contacts',
        name: 'Магазины',
    },
]

export default Footer
