import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ISlide } from '../../interfaces/Story'
import cls from './Stories.module.css'
import SliderLoader from '../../assets/images/loaderStory.gif'
import BlackScreen from '../../assets/images/transparentBG.png'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'ts-luxon'
import SoundOn from '../../assets/images/soundOn.svg'
import SoundOff from '../../assets/images/soundOff.svg'

const parseDate = (date: string) => {
    const d = DateTime.fromISO(date)
    if (!d.isValid) return ''
    if (DateTime.now().year === d.year) return d.toLocaleString({ day: '2-digit', month: 'short' })

    return d.toLocaleString({ day: '2-digit', month: 'short', year: 'numeric' })
}
interface StoriesProps {
    name: string
    preview: string
    date: string
    slides: ISlide[]
    isActive: boolean
    onAllSlidesEnd: () => void
    slidePrev: () => void
    muted: boolean
    setMuted: () => void
}

export const Stories = ({
    slides,
    isActive,
    onAllSlidesEnd,
    slidePrev,
    name,
    preview,
    date,
    muted,
    setMuted,
}: StoriesProps) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
    const [percent, setPercent] = useState(0)

    const nav = useNavigate()
    const navigate = (url: string) => {
        if (!isActive) return
        const path = url.replace('https://parfumeratelier.ru', '')
        nav(path)
    }
    const onPrev = () => {
        if (currentSlideIndex === 0) {
            slidePrev()
            return
        }
        setCurrentSlideIndex((prev) => prev - 1)
        setPercent(0)
    }
    const onNext = () => {
        if (currentSlideIndex === slides.length - 1) {
            onAllSlidesEnd()
            return
        }
        setCurrentSlideIndex((prev) => prev + 1)
        setPercent(0)
    }

    const slide = useMemo(() => {
        return slides[currentSlideIndex]
    }, [currentSlideIndex, slides])

    return (
        <div className={cls.StoriesContainer}>
            <div className={cls.StoryDurations}>
                {slides.map((s, i) => (
                    <ProgressItem
                        key={s._id}
                        isActive={s._id === slide._id}
                        hasStoryPassed={currentSlideIndex > i}
                        percent={percent}
                    />
                ))}
            </div>
            <div className={cls.NavigationPrev} onClick={onPrev}></div>
            <div className={cls.NavigationNext} onClick={onNext}></div>
            <div className={cls.StoryInfo}>
                <img src={preview} />
                <span>{name}</span>
            </div>
            {slide.contentType.split('/')[0] === 'video' && (
                <StoryVideo
                    key={slide._id}
                    slide={slide}
                    isActive={isActive}
                    setPercent={setPercent}
                    muted={muted}
                    setMuted={setMuted}
                    onSlideEnd={() => {
                        setTimeout(() => {
                            if (currentSlideIndex === slides.length - 1) onAllSlidesEnd()
                            else {
                                setCurrentSlideIndex((prev) => prev + 1)
                                setPercent(0)
                            }
                        }, 250)
                    }}
                />
            )}
            {slide.contentType.split('/')[0] === 'image' && (
                <StoryImg
                    key={slide._id}
                    slide={slide}
                    isActive={isActive}
                    setPercent={setPercent}
                    onSlideEnd={() => {
                        setTimeout(() => {
                            if (currentSlideIndex === slides.length - 1) onAllSlidesEnd()
                            else {
                                setCurrentSlideIndex((prev) => prev + 1)
                                setPercent(0)
                            }
                        }, 250)
                    }}
                />
            )}
            {slide.action && (
                <div
                    onClick={() => navigate(slide.action.path)}
                    style={{
                        backgroundColor: slide.action.backgroundColor,
                        color: slide.action.textColor,
                    }}
                    className={cls.Action}>
                    {slide.action.title}
                </div>
            )}
        </div>
    )
}

interface ProgressItemProps {
    isActive: boolean
    hasStoryPassed: boolean
    percent: number
}

const ProgressItem = ({ isActive, hasStoryPassed, percent }: ProgressItemProps) => {
    const WIDTH = hasStoryPassed ? 1 : isActive ? percent : 0

    return (
        <div className={cls.StoryDurationItem}>
            <div
                style={{ '--width': WIDTH } as React.CSSProperties}
                className={clsx(isActive && cls.Active, hasStoryPassed && cls.Passed)}></div>
        </div>
    )
}

interface StoryItemProps {
    isActive: boolean
    setPercent: (percent: number) => void
    slide: ISlide
    onSlideEnd: () => void
}

const StoryVideo = ({
    isActive,
    setPercent,
    slide,
    onSlideEnd,
    muted,
    setMuted,
}: StoryItemProps & { muted: boolean; setMuted: () => void }) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const [error, setError] = useState(false)

    const onDurationChangeHandler = (e: React.ChangeEvent<HTMLVideoElement>) => {
        setPercent(e.target.currentTime / e.target.duration)
    }

    useEffect(() => {
        if (videoRef.current) videoRef.current.muted = muted
    }, [muted])

    useEffect(() => {
        if (videoRef.current)
            if (isActive) {
                setPercent(0)
                videoRef.current.currentTime = 0
                videoRef.current.play()
            } else videoRef.current.pause()

        const detectTabs = () => {
            if (document.visibilityState === 'visible') {
                if (isActive) videoRef.current?.play()
            } else {
                videoRef.current?.pause()
            }
        }
        document.addEventListener('visibilitychange', detectTabs)
        return () => {
            document.removeEventListener('visibilitychange', detectTabs)
        }
    }, [isActive])

    if (error)
        return (
            <div className={clsx(cls.StoryImg, cls.StoryError)}>
                <p>Произошла ошибка при загрузке истории</p>
            </div>
        )
    return (
        <>
            <img
                src={muted ? SoundOff : SoundOn}
                className={cls.VideoSound}
                onClick={() => setMuted()}
            />
            <video
                width={'100%'}
                ref={videoRef}
                // onLoadedData={startVideo}
                style={{ backgroundImage: `url(${SliderLoader})` }}
                onTimeUpdate={onDurationChangeHandler}
                poster={''}
                controls={false}
                playsInline={true}
                onLoad={() => setError(false)}
                onError={() => setError(true)}
                className={cls.StoryVideo}
                onEnded={onSlideEnd}>
                <source src={slide.url} type={slide.contentType} />
            </video>
        </>
    )
}

const StoryImg = ({ isActive, setPercent, slide, onSlideEnd }: StoryItemProps) => {
    const [src, setSrc] = useState(BlackScreen)
    const [isImageReady, setImageReady] = useState(false)
    const [error, setError] = useState(false)
    const durationDefault = 4
    useEffect(() => {
        const img = new Image()
        img.src = slide.url
        img.onload = () => {
            setError(false)
            setSrc(slide.url)
            setImageReady(true)
        }
        img.onerror = () => {
            setError(true)
        }
    }, [])

    useEffect(() => {
        let interval: NodeJS.Timer
        const now = Date.now()
        let closeTab = Date.now()
        let openTab = Date.now()
        let hiddenDuration = 0
        const detectTabs = () => {
            if (document.visibilityState === 'visible') {
                openTab = Date.now()
                hiddenDuration += openTab - closeTab
                if (isActive && isImageReady) {
                    interval = setInterval(() => {
                        const onePercent = (durationDefault * 1000) / 100
                        const totalPercent = (Date.now() - now) / onePercent
                        const skipPercent = hiddenDuration / onePercent
                        setPercent((totalPercent - skipPercent) / 100)
                        if (totalPercent - skipPercent >= 100) {
                            onSlideEnd()
                            clearInterval(interval)
                            return
                        }
                    }, 250)
                }
            } else {
                closeTab = Date.now()
                clearInterval(interval)
            }
        }
        document.addEventListener('visibilitychange', detectTabs)
        detectTabs()

        return () => {
            clearInterval(interval)
            document.removeEventListener('visibilitychange', detectTabs)
        }
    }, [isActive, isImageReady])

    if (error)
        return (
            <div className={clsx(cls.StoryImg, cls.StoryError)}>
                <p>Произошла ошибка при загрузке истории</p>
            </div>
        )
    return (
        <img
            style={{ backgroundImage: `url(${SliderLoader})` }}
            className={cls.StoryImg}
            src={src}
            alt={slide.action?.title || ''}
        />
    )
}
